import "./PortfolioList.css"
import Project from "./Project/Project"
import { forwardRef } from "react";
import ProjectCarousel from "./Carousel/ProjectCarousel";

function PortfolioList({projects})
{
    
    return (
        
        <div id ="portfolioDiv">
            <h1>Portfolio</h1>   
             <ProjectCarousel/>
            <span id="portfolioSpan">
                

            </span>   
         </div> 
    );
    
}

export default PortfolioList;
import "./NavBar.css"
import { slide as Menu } from 'react-burger-menu'

function NavBar({ref0,ref1,ref2,ref3})
{
 
    function scrollTo(ref) 
    {
      if (!ref.current) return;
      ref.current.scrollIntoView({ block:"start",behavior: "smooth" });
    }
    return (
        
        <div id="NavBar" className="sticky">  

            
            
            <span>
                <img src="CDT_logo_white.png" id ="logo"></img>
            </span>
          
            <span className= "linkButtons"> 
                <button className = "scrollButton" onClick={() => scrollTo(ref0)}>Home</button>
                <button className = "scrollButton" onClick={() => scrollTo(ref1)}>About</button>
                <button className = "scrollButton" onClick={() => scrollTo(ref2)}>Portfolio</button>
                <button className = "scrollButton"onClick={() => scrollTo(ref3)}>Contact</button>
                <a href="https://www.linkedin.com/in/chastin-taylor/" target ="_blank" rel="noreferrer"><img src="linkedin_icon.png" alt="linkedin" className="navImage"></img></a>
                <a href="https://github.com/ChastinT" target ="_blank" rel="noreferrer"><img src="github-mark-white.png" alt="github" className="navImage"></img></a>
            </span>
            <span id="hamburger-menu">
               <Menu right>
               <button className = "scrollButton" onClick={() => scrollTo(ref0)}>Home</button>
               <button className = "scrollButton" onClick={() => scrollTo(ref1)}>About</button>
                <button className = "scrollButton" onClick={() => scrollTo(ref2)}>Portfolio</button>
                <button className = "scrollButton"onClick={() => scrollTo(ref3)}>Contact</button>

               </Menu>
            </span>
        </div>

    )
}

export default NavBar
import "./About.css"

function About() {
    return (
      <div id="about">
        <h1 id="aboutTitle">About Me</h1>
        <p id = "aboutIntro">
          Here are some deeper insights into my profile, 
          <br></br>
          unveiling a showcase of skills and expertise across various frameworks.</p>
          <br></br>
        <span id="aboutContent">
          
          <div id="aboutDescription">
          <h4 class="highlight">Summary</h4>
            <p id="summary_section">
              I bring extensive expertise as a Full Stack Developer, 
              seamlessly integrating captivating front-end design with resilient backend systems to drive project success. 
              Passionate about continuous improvement, I actively seek opportunities that allow me to contribute, learn, and grow. 
              <br></br><br></br>
              
              Open to exploring roles that align with my skill set, I look forward to engaging discussions about how I can make impactful contributions to innovative projects. 
              If you have a compelling opportunity, I am eager to connect and bring my unique blend of skills to your team.
            </p>
          </div>

          <div id= "aboutSkills" class>
            <h4 class="highlight">Languages / Frameworks</h4>
            <button>Java</button>
            <button>C++</button>
            <button>C#</button>
            <button>HTML</button>
            <button>CSS</button>
            <button>Javascript</button>
            <button>React</button>
            <button>MySQL</button>
            <button>Apex</button>
            <button>SOQL</button>
            <button>Postman</button>
            <button>SoapUI</button>
          </div>
          
        </span>
      </div>
    );
  }
  
  export default About;
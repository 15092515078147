import "./Contact.css";
import emailjs from 'emailjs-com';
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

function Contact()
{
 const form = useRef();
 function sendEmail(e)
 {
    e.preventDefault();
    emailjs.sendForm("service_0hlwq5u", "template_6fa7zki",e.target, "D8LQsmGTOwnENX038")
    .then((result) =>
    {
        window.location.reload();
    }, (error) =>
    {
        console.log(error.message);
    });
 }
   
    return (
        
        <div id="contactSection">
            <script src="https://www.recaptcha.net/recaptcha/api.js" async defer></script>
            <h1>Contact</h1>
            <p id="thankYouText">Thank you for visiting my website! 
            <br></br>For collaboration, discussions, advice, or a friendly chat, don't hesitate to get in touch below.</p>
            <div id="contactForm">
                <form ref = {form} onSubmit={sendEmail}>
                    <fieldset>
                        <br></br>
                        <label>Name</label>
                        <br></br>
                        <br></br>
                        <input type="text" name="from_name" placeholder="Enter your name"></input>
                        <br></br>
                        <br></br>
                        <label>Email *</label>
                        <br></br>
                        <br></br>
                        <input type="text" name="from_email" placeholder="Enter your email" required></input>
                        <br></br>
                        <br></br>
                        <label>Message *</label>
                        <br></br>
                        <br></br>
                        <textarea rows="10" cols="100" name="message" placeholder="Enter your message" required> </textarea>
                        <br></br>
                        <br></br>
                        <button id="submit-button" >SUBMIT</button>
                        <br></br>
                        <div id="captcha-span">

                        
                        <span >
                            <p>
                                This site is protected by reCAPTCHA and  <br></br>
                                the Google{' '}
                                <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}<br></br>
                                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </p>
                            <ReCAPTCHA id="captcha" sitekey="6LeyMxQpAAAAAFAdMs48t7eGdMN7XtXCT6VOHuT4" />
                        </span>
                        </div>


                    </fieldset>
                </form>
            </div>
        </div>

    )
}

export default Contact
import React from 'react';
import "./Project.css"

function Project({project,onClick}) {

  return (
    <span id="project" onClick = {onClick}>
      <img id="project-img" src={project.imageSrc} alt={project.name} />
      <div id="project-details">
        <h3>{project.name}</h3>
        <p>{project.details.languages.join(', ')}</p>
        <p id="project-summary">{project.details.summary}</p>
        <a href={project.details.downloadLink}><button id="project-viewButton">VIEW LINK</button></a>
      </div>
    </span>
  );
}

export default Project;
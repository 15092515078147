// ProjectCarousel.js
import React from 'react';
import { useState,useEffect } from 'react';
import ThumbnailItem from './ThumbnailItem'; // Create this component for individual thumbnail items
import Project from "../Project/Project"
import "./ProjectCarousel.css"

const projects = [
  {
    id: 1,
    name: 'Tonji\'s Izakaya',
    imageSrc: 'Tonji_Banner.png',
    details: {
      summary: 'Horror cooking game developed as \n part of Horror Jam 2023 on Itch.io',
      languages: ['Unity', 'C#'],
      downloadLink: 'https://b0mbhead.itch.io/tonjis-izakaya',
    },
  },
  {
    id: 2,
    name: 'AI Tic Tac Toe',
    imageSrc: 'AI_Tic_Tac_Toe_Background.png',
    details: {
      summary: 'AI Tic Tac Toe Game developed for AI class in college',
      languages: ['Python'],
      downloadLink: 'https://github.com/ChastinT/Mini_Project_1_AI_Tac_Tac_Toe/tree/master',
    },
  },

  {
    id: 3,
    name: 'AI Hereditary Calculator',
    imageSrc: 'Placeholder_Thumbnail.png',
    details: {
      summary: 'AI Program to calculate different possible genetic traits between two families \n developed for AI class in college',
      languages: ['Python'],
      downloadLink: 'https://github.com/ChastinT/AI_Hereditary_Calculation',
    },
  },

  {
    id: 4,
    name: 'Project 2',
    imageSrc: 'Placeholder_Thumbnail.png',
    details: {
      summary: 'Test 2',
      languages: ['Java'],
      downloadLink: 'https://drive.google.com/drive/my-drive',
    },
  },

  {
    id: 5,
    name: 'Project 2',
    imageSrc: 'Placeholder_Thumbnail.png',
    details: {
      summary: 'Test 2',
      languages: ['Java'],
      downloadLink: 'https://drive.google.com/drive/my-drive',
    },
  },

  {
    id: 6,
    name: 'Project 2',
    imageSrc: 'Placeholder_Thumbnail.png',
    details: {
      summary: 'Test 2',
      languages: ['Java'],
      downloadLink: 'https://drive.google.com/drive/my-drive',
    },
  },
  // Add more projects as needed
];



function ProjectCarousel() {
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [projectsToShow, setProjectsToShow] = useState(4);

    useEffect(() => {
      setSelectedLanguage("All Languages");
    }, []);

    const handleThumbnailClick = (projectId) => {

        setSelectedProject(projectId);
      };

    const handleFilterChange = (event) =>
    {
      setSelectedLanguage(event.target.value);
      setProjectsToShow(4);
    }


    {/*Filter projects based on Selected Languages*/}
    let filteredProjects = selectedLanguage !== "All Languages" 
    ? projects.filter((project) => project.details.languages.includes(selectedLanguage) )
    : projects.slice(0, projectsToShow);
    
    let totalFilteredSize = filteredProjects.length;
    filteredProjects = filteredProjects.slice(0,projectsToShow);

    

        const selectedProjectDetails = selectedProject !== null ? projects.find(proj => proj.id === selectedProject)?.details : null;

  return (
    <div className="project-carousel">
        <div className="display-image">
          {selectedProjectDetails && (
            <div className="project-details">
              <h3>{selectedProjectDetails.name}</h3>
              {selectedProjectDetails.summary && <p>{selectedProjectDetails.summary}</p>}
              {selectedProjectDetails.languages && <p>Languages: {selectedProjectDetails.languages.join(', ')}</p>}
              {selectedProjectDetails.downloadLink && <a href={selectedProjectDetails.downloadLink} target ="_blank"><button>VIEW PROJECT</button></a>}
            </div>
          )}
          {/* Display the giant image */}
          <img src={selectedProject !== null ? projects.find(proj => proj.id === selectedProject)?.imageSrc : 'placeholder_640_360.png'} alt="Giant Project Image" />
        </div>
        <br></br>
        <div id="projectFilter">
          <label>Filter by Language: </label>
          <select id="languageFilter" onChange={handleFilterChange} value={selectedLanguage || ''}>
          <option value="All Languages" selected>All Languages</option>
          {Array.from(
            new Set(
              projects.flatMap((project) =>
                project.details.languages || []
              )
            )
          ).map((language) => (
            <option key={language} value={language}>
              {language}
            </option>
            ))}

          </select>
        </div>
        <div>
          <p className="setTextWhite">Only Showing Recent {projectsToShow}</p>
        </div>
        <span className="thumbnail-container">
            {filteredProjects.map((project) => (
            <Project key={project.id}
            project={project}
            onClick={() => handleThumbnailClick(project.id)}
            />
            ))} 
        </span>
        <div>
          {totalFilteredSize > projectsToShow ? (<button onClick={() => setProjectsToShow(projectsToShow + 4)} id="showProjectsButton">SHOW MORE</button>): null}
        </div>
    </div>
  );
}

export default ProjectCarousel;

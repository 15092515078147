
import './App.css';
import HomeSection from "./Home/HomeSection";
import NavBar from "./NavBar/NavBar";
import About from './AboutSection/About';
import PortfolioList from "./PortfolioList/PortfolioList";
import Contact from "./ContactSection/Contact";
import { useRef } from 'react';

function App() {
  let home = useRef();
  let about = useRef();
  let portfolioList = useRef();
  let contact = useRef();
  return (
    
    <div id="page">
      <div>
         <NavBar  ref0 = {home} ref1={about} ref2={portfolioList} ref3={contact}/>
      </div>
  
      <div ref = {home}>
       <HomeSection ref1={portfolioList}/>
      </div>
      
      <div ref ={about}>
        <About/>
      </div>

      <div ref={portfolioList}>
        <PortfolioList />
      </div>

      <div ref={contact}>
        <Contact />
      </div>

    </div>
  );
}

export default App;

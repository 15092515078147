import "./HomeSection.css"
import { useRef } from 'react';

function HomeSection({ref1}) {

  
  function scrollTo(ref) 
  {
    if (!ref.current) return;
    ref.current.scrollIntoView({ block:"start",behavior: "smooth" });
  }
    return (
      <div id="home">
        <h1 id="aboutTitle">Hi, I'm <span class="highlight">Chastin Taylor.</span></h1>
        <p id= "intro"> 
        A seasoned Full Stack Developer, who crafts applications, websites, and software, 
        <br></br>
        bridging backend and frontend technologies for successful outcomes.
          </p>
      <button id="project-button" onClick={() => scrollTo(ref1)}>Projects</button>
          <br></br>
      </div>
    );
  }
  
  export default HomeSection;
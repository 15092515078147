// ThumbnailItem.js

import React from 'react';

function ThumbnailItem({ project, onClick }) {
  return (
    <div className="thumbnail-item" onClick={onClick}>

      <img src={project.imageSrc} alt={project.name} />
      <p>Name Holder</p>
    </div>
  );
}

export default ThumbnailItem;
